import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

const useAuth = () => {
  let navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_SERVER_URL;

  const logout = async () => {
    await Axios({
      method: 'get',
      baseURL: baseUrl,
      url: '/auth/logout',
      withCredentials: true,
      validateStatus: null,
    });

    return null;
  };

  const login = async (username: string, password: string) => {
    const response = await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/auth/login',
      data: {
        username: username,
        password: password,
      },
      withCredentials: true,
      validateStatus: null,
    });
    if (response.data.unauthorized || response.status !== 200) {
      if (response.data.isUser) {
        return { isUser: response.data.isUser };
      }
      if (response.data.password) {
        return { password: response.data.password };
      }
      return null;
    }

    navigate('/admin');
    return response.data;
  };

  const checkLogin = async () => {
    const response = await Axios({
      method: 'get',
      baseURL: baseUrl,
      url: 'auth/checklogin',
      withCredentials: true,
      validateStatus: null,
    });
    if (response.data.unauthorized || response.status !== 200) {
      return null;
    } else {
      return response.data;
    }
  };

  return {
    login,
    logout,
    checkLogin,
  };
};

export default useAuth;
