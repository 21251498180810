import styled from 'styled-components';
import CategoryItemWrapper from './item';

export const CategoryWrapper = styled('div')`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: min-content min-content;
`;

export const CategoryTitleWrapper = styled('div')<{
  $backgroundColor: string | undefined;
}>`
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 1rem;
  margin-top: 2rem;
  width: 100%;

  text-transform: uppercase;
  color: white;
  background-color: ${(props) => props.$backgroundColor};
  font-weight: 500;
  border-radius: 1.5rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 1rem;
  font-size: clamp(1rem, 1.6vw, 4rem);

  @media screen and (max-width: 1440px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
    font-size: clamp(1rem, 1.6vw, 2.3rem);
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(1rem, 1.4vw, 2.3rem);
  }
  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(0.4rem, 1.4vw, 2.3rem);
    column-gap: 0.2rem;
  }
`;

export const CategoryTitle = styled('div')`
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

export const CategoryTitleThc = styled('div')`
  justify-self: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;
export const CategoryTitlePrice = styled('div')`
  grid-column: 3/-1;
  justify-self: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

export const CategoryBody = styled('ul')`
  margin-top: 1rem;
  display: grid;
  width: 100%;
  padding-left: 4rem;
  padding-right: 1rem;
  height: min-content;
  margin-bottom: 0;
  font-weight: 300;

  @media screen and (max-width: 1440px) {
    padding-left: 3rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    column-gap: 0.2rem;
  }
`;

export const CategoryItem = styled('li')`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  grid-template-columns: 1fr auto auto;
  font-size: clamp(1rem, 1.2vw, 3rem);
  color: white;
  text-transform: uppercase;
  height: min-content;
  @media screen and (max-width: 768px) {
    column-gap: 0.2rem;
    font-size: clamp(0.5rem, 1.2vw, 1rem);
  }
`;

export const CategoryItemName = styled('div')<{
  $textColor: string | undefined;
}>`
  color: ${(props) => {
    if (props.$textColor) {
      return props.$textColor;
    } else {
      return '#ffffff';
    }
  }};
  width: 100%;
  grid-row: 1/2;
  grid-column: 1/-1;
`;
export const CategoryItemDescription = styled('div')`
  line-height: min-content;
  font-size: clamp(1rem, 0.8vw, 2rem);
  color: #1bff01;
  height: max-content;

  padding-left: 0.5rem;
  font-weight: 400;
  padding-bottom: 0.2rem;

  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(0.3rem, 0.8vw, 1rem);
  }
`;

export const CategoryItemThc = styled('div')`
  padding-right: clamp(0.1rem, 0.6vw, 1.5rem);
  text-align: center;
  grid-column: 2/3;
  grid-row: 1/2;
  @media screen and (max-width: 768px) {
    padding-right: 0.3rem;
  }
`;
export const CategoryItemPrice = styled('div')`
  padding-right: clamp(0.1rem, 0.8vw, 2rem);
  text-align: center;
  grid-column: 3/4;
  grid-row: 1/2;
  @media screen and (max-width: 768px) {
    padding-right: 0.1rem;
  }
`;

const Category = ({
  data,
  textColor,
}: {
  data: {
    title?: string;
    thc?: boolean;
    custom?: boolean;
    color?: string;
    itemGroup: number;
    items?: {
      name: string;
      artikelId: string;
      description?: string;
      hidden?: boolean;
      thcLvl?: number;
      custom?: boolean;
      price: number;
      amount: number;
      drink?: boolean;
    }[];
  };
  textColor: boolean;
}) => {
  const filteredItems = data.items?.sort((a: any, b: any) => {
    if (a.price > b.price) {
      return 1;
    } else if (a.price === b.price) {
      return a.name.localeCompare(b.name);
    } else {
      return -1;
    }
  });

  return (
    <CategoryWrapper>
      <CategoryTitleWrapper $backgroundColor={data.color}>
        <CategoryTitle>{data.title}</CategoryTitle>

        {data.thc && <CategoryTitleThc>THC</CategoryTitleThc>}
        <CategoryTitlePrice>Price</CategoryTitlePrice>
      </CategoryTitleWrapper>
      <CategoryBody>
        {filteredItems &&
          filteredItems.map((item, key): any => {
            return (
              item.amount > 0.2 &&
              item.hidden === false && (
                <CategoryItemWrapper key={key} item={item} data={data} />
              )
            );
          })}
      </CategoryBody>
    </CategoryWrapper>
  );
};

export default Category;
