import styled from 'styled-components';
import ItemSection from './section';

const ItemsBody = styled('div')`
  width: 50%;
  display: grid;
  column-gap: 2rem;
  font-size: 3.5rem;
  box-sizing: border-box;
  align-items: center;
  overflow: auto;
`;

export default function Items({
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
}: any) {
  return (
    <ItemsBody>
      <ItemSection array={sectionOne} />
      <ItemSection array={sectionTwo} />
      <ItemSection array={sectionThree} />
      <ItemSection array={sectionFour} />
    </ItemsBody>
  );
}
