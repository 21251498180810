import { useEffect, useRef, useState } from 'react';
import TextField from '../../../global/textfield';
import UseMenu from '../../../../hooks/menu/index';
import {
  AdminButtons,
  AdminCategoryThc,
  AdminCategoryWrapper,
  AdminColorButton,
  AdminColorButtonWrapper,
  AdminColorPickerWrapper,
  AdminColorSwatch,
  AdminColorWrapper,
  AdminDeleteButton,
  AdminItemGroup,
  AdminSaveButton,
  AdminSwitchButton,
  AdminTextField,
  HexCustomInput,
  HexCustomWrapper,
  HexSelectWrapper,
} from '../style';
import { HexColorPicker } from 'react-colorful';
import { Colors } from '../../../../assets/style/global';
import styled from 'styled-components';
import validator from 'validator';

const AddWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: grid;
`;

const AddButton = styled('button')`
  height: 100%;
  width: 100%;
  display: grid;
  background-color: transparent;
  border-radius: 1rem;
  border: 0.2rem solid ${Colors.gray_light};

  &:hover {
    cursor: pointer;
    background-color: rgba(145, 145, 145, 0.137);
  }

  &:after {
    justify-self: center;
    align-self: center;
    content: '\\e900';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;
    color: white;
    font-size: 10rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const AddCategory = () => {
  const [title, setTitle] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const [itemGroupError, setItemGroupError] = useState<string>('');
  const [color, setColor] = useState<string>('#ffffff');
  const [thc, setThc] = useState<boolean>(false);
  const [custom, setCustom] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<boolean>(false);
  const [editColor, setEditColor] = useState<boolean>(false);
  const [itemGroup, setItemGroup] = useState<number>(0);
  const ref = useRef<any>();
  const buttonRef = useRef<any>();
  const { createItem } = UseMenu();

  const [addCategory, setAddCategory] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setEditColor(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const resetStates = () => {
    setTitle('');
    setColor('#ffffff');
    setThc(false);
    setCustom(false);
    setEditColor(false);
    setItemGroup(0);
    setTitleError('');
    setItemGroupError('');
    setAddCategory(false);
  };

  const createCategory = async () => {
    if (validator.isEmpty(title)) {
      setTitleError('Title cannot be empty!');
      return;
    }
    const data: any = await createItem({
      title: title,
      thc: thc,
      itemGroup: itemGroup,
      color: color,
      order: 0,
      custom: custom,
      textColor: textColor,
    });

    if (data) {
      resetStates();
    } else {
      setItemGroupError(
        'Item group already exists, Choose a different number!'
      );
    }
  };

  return (
    <AdminCategoryWrapper $color={Colors.gray}>
      {!addCategory ? (
        <AddWrapper>
          <AddButton onClick={() => setAddCategory(true)}></AddButton>
        </AddWrapper>
      ) : (
        <>
          <AdminTextField>
            <TextField
              label={'Title'}
              name='Title'
              value={title}
              type='text'
              error={titleError}
              onChange={(e: any) => {
                setTitleError('');
                setTitle(e.target.value);
              }}
              validate={true}
            />
          </AdminTextField>
          <AdminColorWrapper>
            Colors:
            <HexCustomWrapper>
              HexCode:
              <HexCustomInput
                color={color}
                onChange={setColor}
                $color={color}
              />
            </HexCustomWrapper>
            <HexSelectWrapper>
              Select:
              <AdminColorButtonWrapper>
                <AdminColorSwatch
                  ref={buttonRef}
                  onClick={() => {
                    setEditColor(!editColor);
                  }}
                >
                  <AdminColorButton $color={color} />
                </AdminColorSwatch>
                {editColor && (
                  <AdminColorPickerWrapper ref={ref}>
                    <HexColorPicker color={color} onChange={setColor} />
                  </AdminColorPickerWrapper>
                )}
              </AdminColorButtonWrapper>
            </HexSelectWrapper>
          </AdminColorWrapper>
          <AdminItemGroup>
            <TextField
              label={'Item Group'}
              name='ItemGroup'
              value={itemGroup}
              type='number'
              error={itemGroupError}
              onChange={(e: any) => {
                setItemGroupError('');
                setItemGroup(e.target.value);
              }}
              validate={false}
            />
          </AdminItemGroup>
          <AdminCategoryThc>
            THC:
            <AdminSwitchButton
              $state={true}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={thc}
              onClick={() => setThc(true)}
            >
              ON
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={!thc}
              onClick={() => setThc(false)}
            >
              OFF
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminCategoryThc>
            Custom:
            <AdminSwitchButton
              $state={true}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={custom}
              onClick={() => setCustom(true)}
            >
              True
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={!custom}
              onClick={() => setCustom(false)}
            >
              False
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminCategoryThc>
            Text color:
            <AdminSwitchButton
              $state={true}
              style={{ cursor: 'pointer' }}
              disabled={textColor}
              onClick={() => setTextColor(true)}
            >
              True
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ cursor: 'pointer' }}
              disabled={!textColor}
              onClick={() => setTextColor(false)}
            >
              False
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminButtons>
            <AdminSaveButton onClick={() => createCategory()}>
              Add
            </AdminSaveButton>
            <AdminDeleteButton onClick={() => resetStates()}>
              Cancel
            </AdminDeleteButton>
          </AdminButtons>
        </>
      )}
    </AdminCategoryWrapper>
  );
};
export default AddCategory;
