/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/auth';
import { io } from 'socket.io-client';
import AdminLogin from '../../components/admin/login';
import CreateCategory from '../../components/admin/create';
import { Colors } from '../../assets/style/global';
import AdminPanel from '../../components/admin/panel';
import { NavLink, Route, Routes } from 'react-router-dom';
import Menu from '../menu';
import Order from '../../components/admin/order';
import Categories from '../../components/admin/categories';
import Items from '../../components/admin/items';

const AdminRoutes = styled(Routes)`
  width: 100%;
  display: grid;
  justify-items: center;
  box-sizing: border-box;
  height: 100%;
  position: relative;
`;

const AdminWrapper = styled('div')`
  width: 100%;
  display: grid;
  justify-items: center;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  grid-template-columns: 20rem 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 8rem;
  }
`;

const AdminLoginWrapper = styled('div')`
  width: 100%;
  display: grid;
  justify-items: center;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  position: relative;
`;

const AdminPanelBody = styled(Routes)`
  overflow: scroll !important;
  height: 2000rem;
  width: 100%;
`;

const AdminBackButton = styled(NavLink)`
  z-index: 2;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  border: none;
  border-radius: 50%;
  left: 2rem;
  position: fixed;
  background-color: ${Colors.primary};
  display: grid;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 4rem;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  padding: 0;
  text-decoration: none;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: '\\e908';
  }
`;

const sortItems = (category: any, menuItem: any) => {
  if (category.find((category: any) => category.id === menuItem.id)) {
    const filteredItems = category.filter(
      (item: any) => item.id !== menuItem.id
    );
    const sortedList = [...filteredItems, menuItem];

    return sortedList.sort((a: any, b: any) => a.order - b.order);
  } else {
    const sortedList = [...category, menuItem];

    return sortedList.sort((a: any, b: any) => a.order - b.order);
  }
};

const Admin = () => {
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  const [section1, setSection1] = useState<any[]>([]);
  const [section2, setSection2] = useState<any[]>([]);
  const [section3, setSection3] = useState<any[]>([]);
  const [section4, setSection4] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [addCategory, setAddCategory] = useState<boolean>(false);

  const { checkLogin } = useAuth();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    (async function () {
      try {
        const data = await checkLogin();
        if (data) {
          setUser(data);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socket = io(`${baseUrl}/menu?menuId=2435`);
    socket.on('connect', () => {});

    socket.on('disconnect', () => {});

    socket.on('getMenu', (data: any) => {
      setSection1([]);
      setSection2([]);
      setSection3([]);
      setSection4([]);
      setCategories([]);
      data.forEach((menuItem: any) => {
        setCategories((category) => [...category, menuItem]);
        if (menuItem.section === 1) {
          setSection1((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 2) {
          setSection2((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 3) {
          setSection3((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 4) {
          setSection4((category) => sortItems(category, menuItem));
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return user ? (
    <AdminRoutes>
      <Route
        path='/preview'
        element={
          <>
            <AdminBackButton to={'/admin/order'} />
            <Menu />
          </>
        }
      />
      <Route
        path='*'
        element={
          <AdminWrapper>
            <AdminPanel />
            <AdminPanelBody>
              <Route
                path='/order'
                element={
                  <Order
                    sectionOne={section1}
                    sectionTwo={section2}
                    sectionThree={section3}
                    sectionFour={section4}
                    setSectionOne={setSection1}
                    setSectionTwo={setSection2}
                    setSectionThree={setSection3}
                    setSectionFour={setSection4}
                  />
                }
              />
              <Route
                path='/categories'
                element={<Categories categories={categories} />}
              />
              {addCategory && (
                <CreateCategory
                  setAddCategory={setAddCategory}
                  section={section1}
                />
              )}
              <Route
                path='/items'
                element={
                  <Items
                    sectionOne={section1}
                    sectionTwo={section2}
                    sectionThree={section3}
                    sectionFour={section4}
                  />
                }
              />
            </AdminPanelBody>
          </AdminWrapper>
        }
      />
    </AdminRoutes>
  ) : (
    <AdminLoginWrapper>
      <AdminLogin
        setUser={setUser}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
      />
    </AdminLoginWrapper>
  );
};

export default Admin;
