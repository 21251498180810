import { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import {
  CategoryItem,
  CategoryItemName,
  CategoryItemDescription,
  CategoryItemThc,
  CategoryItemPrice,
} from '..';
import {
  RemoveItemButton,
  EditItemButton,
} from '../../admin/category/addCustomItem/style';
import styled from 'styled-components';
import UseMenu from '../../../hooks/menu';

const ColorButtonWrapper = styled('div')`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  align-self: start;
`;

const ColorSwatch = styled('div')`
  margin: 0.5rem;
  padding: 0.5rem;
  background: #fff;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  position: relative;
`;

const ColorPickerWrapper = styled('div')`
  position: fixed;
  z-index: 2;
  margin-left: 1rem;
  margin-top: 25rem;
`;

const ColorButton = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
`;

const CategoryItemWrapper = ({ item, data }: { item: any; data: any }) => {
  const buttonRef = useRef<any>();
  const ref = useRef<any>();
  const [editColor, setEditColor] = useState<boolean>(false);
  const [color, setColor] = useState<string | undefined>('');
  const { editTextColor } = UseMenu();

  const updateColor = () => {
    if (item.color !== color) {
      editTextColor({
        item: {
          artikelId: item.artikelId,
          custom: item.custom,
          amount: item.amount,
          name: item.name,
          price: item.price,
          thcLvl: item.thcLvl,
          group: item.group,
          hidden: item.hidden,
          description: item.description,
          color: color,
        },
      });
    }
  };

  useEffect(() => {
    setColor(item.color);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setEditColor(false);
        updateColor();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);
  return (
    <CategoryItem>
      <CategoryItemName $textColor={item.color}>
        {item.name}
        {item.description && (
          <CategoryItemDescription>{item.description}</CategoryItemDescription>
        )}
      </CategoryItemName>
      {data.thc ? (
        item.thcLvl ? (
          <CategoryItemThc>{item.thcLvl}%</CategoryItemThc>
        ) : (
          <CategoryItemThc>-</CategoryItemThc>
        )
      ) : null}

      <CategoryItemPrice>
        {(Math.round(item.price * 100) / 100).toFixed(2)}
      </CategoryItemPrice>
    </CategoryItem>
  );
};

export default CategoryItemWrapper;
