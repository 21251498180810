import UseMenu from '../../../hooks/menu';

const { saveOrder } = UseMenu();
const sortCategory = (category: any, section: number) => {
  category = category.map((item: any, key: number) => {
    let changed = item;
    changed.order = key;
    changed.section = section;
    return changed;
  });
  return category;
};

const changeOrder = (
  result: any,
  setSectionOne: any,
  setSectionTwo: any,
  setSectionThree: any,
  setSectionFour: any,
  sectionOne: any,
  sectionTwo: any,
  sectionThree: any,
  sectionFour: any
) => {
  let categories;
  let reorderedCategories;
  switch (result.source.droppableId) {
    case '1':
      categories = sectionOne;
      [reorderedCategories] = categories.splice(result.source.index, 1);
      switch (result.destination.droppableId) {
        case '1':
          categories.splice(result.destination.index, 0, reorderedCategories);
          setSectionOne(sortCategory(categories, 1));
          break;
        case '2':
          let categoryTwo = sectionTwo;
          categoryTwo.splice(result.destination.index, 0, reorderedCategories);
          setSectionOne(sortCategory(categories, 1));
          setSectionTwo(sortCategory(categoryTwo, 2));
          break;
        case '3':
          let categoryThree = sectionThree;
          categoryThree.splice(
            result.destination.index,
            0,
            reorderedCategories
          );
          setSectionOne(sortCategory(categories, 1));
          setSectionThree(sortCategory(categoryThree, 3));
          break;
        case '4':
          let categoryFour = sectionFour;
          categoryFour.splice(result.destination.index, 0, reorderedCategories);
          setSectionOne(sortCategory(categories, 1));
          setSectionFour(sortCategory(categoryFour, 4));
          break;
      }
      break;
    case '2':
      categories = sectionTwo;
      [reorderedCategories] = categories.splice(result.source.index, 1);
      switch (result.destination.droppableId) {
        case '1':
          let categoryOne = sectionOne;
          categoryOne.splice(result.destination.index, 0, reorderedCategories);
          setSectionTwo(sortCategory(categories, 2));
          setSectionOne(sortCategory(categoryOne, 1));
          break;
        case '2':
          categories.splice(result.destination.index, 0, reorderedCategories);
          setSectionTwo(sortCategory(categories, 2));
          break;
        case '3':
          let categoryThree = sectionThree;
          categoryThree.splice(
            result.destination.index,
            0,
            reorderedCategories
          );
          setSectionTwo(sortCategory(categories, 2));
          setSectionThree(sortCategory(categoryThree, 3));
          break;
        case '4':
          let categoryFour = sectionFour;
          categoryFour.splice(result.destination.index, 0, reorderedCategories);
          setSectionTwo(sortCategory(categories, 2));
          setSectionFour(sortCategory(categoryFour, 4));
          break;
      }
      break;
    case '3':
      categories = sectionThree;
      [reorderedCategories] = categories.splice(result.source.index, 1);
      switch (result.destination.droppableId) {
        case '1':
          let categoryOne = sectionOne;
          categoryOne.splice(result.destination.index, 0, reorderedCategories);
          setSectionThree(sortCategory(categories, 3));
          setSectionOne(sortCategory(categoryOne, 1));
          break;

        case '2':
          let categoryTwo = sectionTwo;
          categoryTwo.splice(result.destination.index, 0, reorderedCategories);
          setSectionThree(sortCategory(categories, 3));
          setSectionTwo(sortCategory(categoryTwo, 2));
          break;
        case '3':
          categories.splice(result.destination.index, 0, reorderedCategories);
          setSectionThree(sortCategory(categories, 3));
          break;
        case '4':
          let categoryFour = sectionFour;
          categoryFour.splice(result.destination.index, 0, reorderedCategories);
          setSectionThree(sortCategory(categories, 3));
          setSectionFour(sortCategory(categoryFour, 4));
          break;
      }
      break;
    case '4':
      categories = sectionFour;
      [reorderedCategories] = categories.splice(result.source.index, 1);
      switch (result.destination.droppableId) {
        case '1':
          let categoryOne = sectionOne;
          categoryOne.splice(result.destination.index, 0, reorderedCategories);
          setSectionFour(sortCategory(categories, 4));
          setSectionOne(sortCategory(categoryOne, 1));
          break;

        case '2':
          let categoryTwo = sectionTwo;
          categoryTwo.splice(result.destination.index, 0, reorderedCategories);
          setSectionFour(sortCategory(categories, 4));
          setSectionTwo(sortCategory(categoryTwo, 2));
          break;
        case '3':
          let categoryThree = sectionThree;
          categoryThree.splice(
            result.destination.index,
            0,
            reorderedCategories
          );
          setSectionFour(sortCategory(categories, 4));
          setSectionThree(sortCategory(categoryThree, 3));
          break;
        case '4':
          categories.splice(result.destination.index, 0, reorderedCategories);
          setSectionFour(sortCategory(categories, 4));
          break;
      }
      break;
  }
};

export const handleOnDragEnd = async ({
  setSectionOne,
  setSectionTwo,
  setSectionThree,
  setSectionFour,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
  result,
}: {
  setSectionOne: any;
  setSectionTwo: any;
  setSectionThree: any;
  setSectionFour: any;
  sectionOne: any;
  sectionTwo: any;
  sectionThree: any;
  sectionFour: any;
  result: { destination: any; source: any };
}) => {
  if (!result.destination) return;
  changeOrder(
    result,
    setSectionOne,
    setSectionTwo,
    setSectionThree,
    setSectionFour,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour
  );
  let section1: any;
  let section2: any;
  if (result.destination.droppableId !== result.source.droppableId) {
    switch (result.destination.droppableId) {
      case '1':
        section1 = sectionOne;
        break;
      case '2':
        section1 = sectionTwo;
        break;
      case '3':
        section1 = sectionThree;
        break;
      case '4':
        section1 = sectionFour;
        break;
    }
    switch (result.source.droppableId) {
      case '1':
        section2 = sectionOne;
        break;
      case '2':
        section2 = sectionTwo;
        break;
      case '3':
        section2 = sectionThree;
        break;
      case '4':
        section2 = sectionFour;
        break;
    }
    await saveOrder({ sectionOne: section1, sectionTwo: section2 });
  } else {
    switch (result.destination.droppableId) {
      case '1':
        section1 = sectionOne;
        break;
      case '2':
        section1 = sectionTwo;
        break;
      case '3':
        section1 = sectionThree;
        break;
      case '4':
        section1 = sectionFour;
        break;
    }
    await saveOrder({ sectionOne: section1 });
  }
};
