import { DragDropContext } from 'react-beautiful-dnd';
import { handleOnDragEnd } from './functions';
import styled from 'styled-components';
import AdminSection from '../section';

const OrderBody = styled('div')`
  width: 100%;
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  font-size: 3.5rem;
  height: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 1440px) {
    column-gap: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    column-gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    column-gap: 0.1rem;
  }
`;

export default function Order({
  setSectionOne,
  setSectionTwo,
  setSectionThree,
  setSectionFour,
  sectionOne,
  sectionTwo,
  sectionThree,
  sectionFour,
}: any) {
  return (
    <DragDropContext
      onDragEnd={(result: any) =>
        handleOnDragEnd({
          setSectionOne,
          setSectionTwo,
          setSectionThree,
          setSectionFour,
          sectionOne,
          sectionTwo,
          sectionThree,
          sectionFour,
          result,
        })
      }
    >
      <OrderBody>
        <AdminSection count={1} array={sectionOne} />
        <AdminSection count={2} array={sectionTwo} />
        <AdminSection count={3} array={sectionThree} />
        <AdminSection count={4} array={sectionFour} />
      </OrderBody>
    </DragDropContext>
  );
}
