// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/icomoon.eot?yhxmkc", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/icomoon.ttf?yhxmkc", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/icomoon.woff?yhxmkc", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/icomoon.svg?yhxmkc", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/RobotoCondensed.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "icomoon";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "MainFont";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}`, "",{"version":3,"sources":["webpack://./src/assets/icons/fonts.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4CAAA;EACA,mPAAA;EAIA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAFF;AAKA;EACE,uBAAA;EACA,4CAAA;AAHF","sourcesContent":["@font-face {\r\n  font-family: 'icomoon';\r\n  src: url('../fonts/icomoon.eot?yhxmkc');\r\n  src: url('../fonts/icomoon.eot?yhxmkc#iefix') format('embedded-opentype'),\r\n    url('../fonts/icomoon.ttf?yhxmkc') format('truetype'),\r\n    url('../fonts/icomoon.woff?yhxmkc') format('woff'),\r\n    url('../fonts/icomoon.svg?yhxmkc#icomoon') format('svg');\r\n  font-weight: normal;\r\n  font-style: normal;\r\n  font-display: block;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'MainFont';\r\n  src: url('../fonts/RobotoCondensed.ttf');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
