import Axios from 'axios';

const UseMenu = () => {
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  const saveData = async (data: {
    id: string;
    title: string | undefined;
    thc: boolean | undefined;
    itemGroup: number | undefined;
    color: string | undefined;
    custom: boolean | undefined;
    textColor: boolean | undefined;
  }) => {
    await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/categories/update',
      data: {
        id: data.id,
        title: data.title,
        thc: data.thc,
        itemGroup: data.itemGroup,
        color: data.color,
        custom: data.custom,
        textColor: data.textColor,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const saveOrder = async (data: {
    sectionOne: any;
    sectionTwo?: any | undefined;
  }) => {
    await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/categories/changeorder',
      data: {
        sectionOne: data.sectionOne,
        sectionTwo: data.sectionTwo,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const createItem = async (data: any) => {
    const response = await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/categories/create',
      data: {
        title: data.title,
        thc: data.thc,
        itemGroup: data.itemGroup,
        color: data.color,
        order: data.order,
        custom: data.custom,
        textColor: data.textColor,
      },
      withCredentials: true,
      validateStatus: null,
    });
    return response.data;
  };

  const createCustomItem = async (data: any) => {
    await Axios({
      method: 'post',
      baseURL: baseUrl,
      url: '/items/add',
      data: {
        title: data.title,
        description: data.description,
        price: data.price,
        itemGroup: data.itemGroup,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const removeCustomItem = async (data: any) => {
    await Axios({
      method: 'post',
      baseURL: baseUrl,
      url: '/items/remove',
      data: {
        artikelId: data,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };
  const editCustomItem = async (data: any) => {
    await Axios({
      method: 'post',
      baseURL: baseUrl,
      url: '/items/edit',
      data: {
        item: data,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const editTextColor = async (data: any) => {
    await Axios({
      method: 'post',
      baseURL: baseUrl,
      url: '/items/editcolor',
      data: {
        item: data,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const deleteItem = async (data: any) => {
    await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/categories/remove',
      data: {
        id: data.id,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  const hideItem = async (data: any) => {
    await Axios({
      method: 'put',
      baseURL: baseUrl,
      url: '/items/hide',
      data: {
        id: data.id,
        hidden: data.hidden,
      },
      withCredentials: true,
      validateStatus: null,
    });
  };

  return {
    hideItem,
    editCustomItem,
    saveData,
    saveOrder,
    createItem,
    deleteItem,
    createCustomItem,
    removeCustomItem,
    editTextColor,
  };
};

export default UseMenu;
