export const Colors = {
  white: '#ffffff',
  error: '#e57373',
  red: '#f44336',
  success: '#81c784',
  primary_dark: '#357a38',
  primary: '#4caf50',
  primary_light: '#6fbf73',
  secondary_dark: '#00a152',
  secondary: 'rgb(0, 230, 118)',
  secondary_light: '#33eb91',
  black: '#000000',
  gray_dark: '#070707',
  gray: '#252525',
  gray_light: '#9e9e9e',
};
