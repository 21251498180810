import { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import styled from 'styled-components';
import UseMenu from '../../../hooks/menu';

const CreateWrapper = styled('div')`
  position: fixed;
  background-color: #38383899;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 3;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const CreateBody = styled('div')`
  padding: 2rem;
  display: grid;
  justify-content: center;
  text-align: center;
  background-color: #020202;
  border-radius: 1.5rem;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.4);
  font-size: clamp(2rem, 2vw, 4.5rem);
  row-gap: 2rem;
  position: relative;
  min-width: min-content;
  max-width: max-content;
`;

const CreateTitle = styled('div')`
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const CreateForm = styled('form')`
  display: grid;
  row-gap: 1rem;
  justify-items: start;
`;

const CreateInputWrapper = styled('div')`
  display: grid;
  grid-template-columns: 13rem 1fr;
  grid-auto-flow: column;
  align-items: center;
  text-align: end;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    grid-auto-flow: row;
    grid-template-columns: auto;
    justify-items: center;
  }
`;

const CreateInput = styled('input')`
  font-size: inherit;
  padding: 0.5rem;
  display: block;
  border-radius: 1rem;
  &:invalid {
    border: 2px solid red;
  }
  &:focus {
    outline: none;
  }
`;

const CreateButton = styled('button')`
  text-transform: uppercase;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #00a800;
  }
  &:disabled {
    background-color: #00a800;
    color: black;
    cursor: default;
  }
`;

const CreateColorSwatch = styled('div')`
  position: relative;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0.5rem;
  background: #fff;
  border-radius: 0.1rem;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
`;

//@ts-ignore
const CreateColorButton = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  cursor: pointer;
  height: 1.4rem;
  width: 3.6rem;
  border-radius: 0.2rem;
`;

const CreateColorPickerWrapper = styled('div')`
  position: fixed;
  z-index: 2;
  margin-left: 7.3rem;
  margin-top: 25rem;
`;

const CreateSubmit = styled('button')`
  justify-self: center;
  color: black;
  background-color: white;
  font-size: 3rem;
  cursor: pointer;
  border-radius: 1rem;
  width: 20rem;
  &:hover {
    background-color: #bdbdbd;
  }
`;

const CreateExit = styled('button')`
  position: fixed;
  color: #000000;
  background-color: #f72828;
  font-size: 3.6rem;
  cursor: pointer;
  border-radius: 4rem;
  width: 4rem;
  height: 4rem;
  padding: 0;

  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    background-color: #b60000;
  }

  &:before {
    top: 50%;
    left: 0;
    content: '\\e904';
  }
`;

const CreateCategory = ({
  section,
  setAddCategory,
}: {
  section: any;
  setAddCategory: any;
}) => {
  const { createItem } = UseMenu();
  const [title, setTitle] = useState<string | undefined>('');
  const [color, setColor] = useState<string | undefined>('black');
  const [editColor, setEditColor] = useState<boolean>(false);
  const [thc, setThc] = useState<boolean | undefined>(false);
  const [custom, setCustom] = useState<boolean | undefined>(false);
  const [itemGroup, setItemGroup] = useState<number | undefined>(0);

  const ref = useRef<any>();
  const buttonRef = useRef<any>();

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setEditColor(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const submit = (e: any) => {
    e.preventDefault();
    const order = section.length + 1;
    createItem({ title, thc, itemGroup, color, order, custom });
    setAddCategory(false);
  };
  return (
    <CreateWrapper>
      <CreateBody>
        <CreateExit onClick={() => setAddCategory(false)}></CreateExit>
        <CreateTitle>Create Category:</CreateTitle>
        <CreateForm onSubmit={submit}>
          <CreateInputWrapper>
            Title:
            <CreateInput
              type='text'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </CreateInputWrapper>
          <CreateInputWrapper>
            Thc:
            <CreateButton
              type='button'
              disabled={thc}
              onClick={() => setThc(true)}
            >
              on
            </CreateButton>
            <CreateButton
              type='button'
              disabled={!thc}
              onClick={() => setThc(false)}
            >
              off
            </CreateButton>
          </CreateInputWrapper>
          <CreateInputWrapper>
            Custom:
            <CreateButton
              type='button'
              disabled={custom}
              onClick={() => setCustom(true)}
            >
              true
            </CreateButton>
            <CreateButton
              type='button'
              disabled={!custom}
              onClick={() => setCustom(false)}
            >
              false
            </CreateButton>
          </CreateInputWrapper>
          <CreateInputWrapper>
            Item Group:
            <CreateInput
              type='number'
              value={itemGroup}
              onChange={(e: any) => setItemGroup(e.target.value)}
            />
          </CreateInputWrapper>
          <CreateInputWrapper>
            Color:
            <CreateColorSwatch
              ref={buttonRef}
              onClick={() => {
                setEditColor(!editColor);
              }}
            >
              <CreateColorButton $color={color} />
            </CreateColorSwatch>
            {editColor && (
              <CreateColorPickerWrapper ref={ref}>
                <HexColorPicker color={color} onChange={setColor} />
              </CreateColorPickerWrapper>
            )}
          </CreateInputWrapper>
          <CreateSubmit>Create</CreateSubmit>
        </CreateForm>
      </CreateBody>
    </CreateWrapper>
  );
};

export default CreateCategory;
