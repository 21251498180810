import styled from 'styled-components';
import ItemItem from '../item';
import { Colors } from '../../../../assets/style/global';
import { useRef, useState } from 'react';
import {
  AdminButtons,
  AdminDeleteButton,
  AdminSaveButton,
  AdminTextField,
} from '../../category/style';
import TextField from '../../../global/textfield';
import color from 'color';
import { title } from 'process';
import UseMenu from '../../../../hooks/menu';
import { isEmpty } from 'validator';

const SectionTitle = styled('div')`
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const SectionTitleWrapper = styled('div')<{ $color: string }>`
  display: grid;
  grid-template-columns: 1fr auto auto 25rem;
  column-gap: 1rem;
  margin-top: 2rem;
  width: 100%;

  text-transform: uppercase;
  color: white;
  background-color: ${(props) => props.$color};
  font-weight: 500;
  border-radius: 1.5rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 1rem;
  font-size: clamp(1rem, 1.6vw, 4rem);

  @media screen and (max-width: 1440px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
    font-size: clamp(1rem, 1.6vw, 2.3rem);
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(1rem, 1.4vw, 2.3rem);
  }
  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(0.4rem, 1.4vw, 2.3rem);
    column-gap: 0.2rem;
  }
`;

const SectionWrapper = styled('div')`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: min-content min-content;
  height: min-content;
`;

const SectionBody = styled('ul')`
  margin-top: 1rem;
  display: grid;
  width: 100%;
  padding-left: 4rem;
  padding-right: 1rem;
  height: min-content;
  margin-bottom: 0;
  font-weight: 300;

  @media screen and (max-width: 1440px) {
    padding-left: 3rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    column-gap: 0.2rem;
  }
`;

const SectionTitleThc = styled('div')`
  justify-self: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const SectionTitlePrice = styled('div')`
  justify-self: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const SectionAdd = styled('div')`
  grid-row: 0/1;
  grid-column: 4/-1;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  justify-self: center;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: end;
  grid-gap: 1rem;
  width: 100%;

  &:after {
    justify-self: start;
    content: '\\e900';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    background-color: rgba(0, 230, 119, 0.1);
  }
  &:active {
    background-color: rgba(0, 230, 119, 0.5);
  }
  border: 0.2rem solid ${Colors.primary};

  &:disabled {
    background-color: rgba(0, 230, 119, 0.5);
    cursor: not-allowed !important;
  }
`;

const AddWrapper = styled('div')`
  background-color: rgba(0, 0, 0, 0.19);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
`;

const AddBody = styled('div')`
  background-color: ${Colors.gray_dark};
  position: fixed;
  z-index: 5;
  top: 50%;
  right: 40%;
  transform: translate(0, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2rem;
  padding: 2rem;
  display: grid;
  justify-items: center;
`;

const AddTitle = styled('div')`
  width: max-content;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 2rem;
`;

const order = (a: any, b: any) => {
  if (a.price > b.price) {
    return 1;
  } else if (a.price === b.price) {
    return a.name.localeCompare(b.name);
  } else {
    return -1;
  }
};

export default function ItemSection({ array }: any) {
  const [addItem, setAddItem] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [itemGroup, setItemGroup] = useState<number>(0);
  const array2 = array.map((category: any) => {
    category.items = category.items.sort(order);
    return category;
  });

  const [nameError, setNameError] = useState<string>('');
  const [priceError, setPriceError] = useState<string>('');

  const { createCustomItem } = UseMenu();

  const saveItem = () => {
    if (isEmpty(name)) {
      setNameError('Name cannot be empty!');
    } else if (price === 0) {
      setPriceError('Price cannot be 0!');
    } else {
      createCustomItem({
        title: name,
        description: description,
        price: price,
        itemGroup: itemGroup,
      });
      setAddItem(false);
    }
  };

  return array2.map((category: any, key: any) => (
    <SectionWrapper key={key}>
      {addItem && (
        <AddWrapper>
          <AddBody>
            <AddTitle>Add Item</AddTitle>
            <AdminTextField>
              <TextField
                label={'Name'}
                name='Name'
                value={name}
                type='text'
                error={nameError}
                onChange={(e: any) => {
                  setNameError('');
                  setName(e.target.value);
                }}
                validate={true}
              />
            </AdminTextField>
            <AdminTextField>
              <TextField
                label={'Description'}
                name='Description'
                value={description}
                type='text'
                error=''
                onChange={(e: any) => setDescription(e.target.value)}
                validate={false}
              />
            </AdminTextField>
            <AdminTextField>
              <TextField
                label={'Price'}
                name='Price'
                value={price}
                type='number'
                error={priceError}
                onChange={(e: any) => {
                  setPriceError('');
                  setPrice(e.target.value);
                }}
                validate={true}
              />
            </AdminTextField>

            <AdminTextField>
              <TextField
                label={'Item Group'}
                name='ItemGroup'
                value={itemGroup}
                type='number'
                error=''
                onChange={(e: any) => setItemGroup(e.target.value)}
                validate={false}
              />
            </AdminTextField>
            <AdminButtons>
              <AdminSaveButton onClick={() => saveItem()}>Add</AdminSaveButton>
              <AdminDeleteButton onClick={() => setAddItem(false)}>
                Cancel
              </AdminDeleteButton>
            </AdminButtons>
          </AddBody>
        </AddWrapper>
      )}
      <SectionTitleWrapper $color={category.color}>
        <SectionTitle>{category.title}</SectionTitle>

        {category.thc && <SectionTitleThc>THC</SectionTitleThc>}
        <SectionTitlePrice>Price</SectionTitlePrice>
        {category.custom && (
          <SectionAdd
            onClick={() => {
              setAddItem(true);
              setItemGroup(category.itemGroup);
            }}
          >
            Add Item
          </SectionAdd>
        )}
      </SectionTitleWrapper>
      <SectionBody>
        {category.items.map((item: any, key: any) => (
          <ItemItem
            item={item}
            key={key}
            custom={category.custom}
            textColor={category.textColor}
            thc={category.thc}
          />
        ))}
      </SectionBody>
    </SectionWrapper>
  ));
}
