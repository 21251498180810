import { Fragment } from 'react';
import Category from '../../category';
import styled from 'styled-components';

const MenuSectionWrapper = styled('div')`
  display: grid;
  grid-auto-rows: min-content;
  overflow: hidden;
`;

const MenuSection = ({ array, isAdmin }: any) => (
  <MenuSectionWrapper>
    {array.map((category: any, categoryKey: any) => (
      <Fragment key={categoryKey}>
        <Category
          key={categoryKey}
          data={category}
          textColor={category.textColor}
        />
      </Fragment>
    ))}
  </MenuSectionWrapper>
);

export default MenuSection;
