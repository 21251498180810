import styled from 'styled-components';
import { Colors } from '../../../assets/style/global';
import logo from '../../../assets/img/logo_small.png';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../../assets/icons';

const AdminPanelWrapper = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${Colors.gray_dark};
  color: white;
  display: grid;
  justify-items: center;
  grid-template-rows: min-content min-content min-content;
  @media screen and (max-width: 768px) {
    position: fixed;
    height: 8rem;
    bottom: 0;
    z-index: 5;
    grid-template-rows: auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr) 8rem repeat(2, 1fr);
    border-top: 0.2rem solid ${Colors.primary};
  }
`;

const AdminPanelLogo = styled('img')`
  margin-top: 5rem;
  width: 50%;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    height: 8rem;
    width: auto;
    margin: 0;
    grid-column: 3/4;
  }
`;

const AdminPanelNavWrapper = styled('div')`
  width: 100%;
  display: grid;
  grid-template-rows: min-content;
  height: min-content;
  @media screen and (max-width: 768px) {
    grid-auto-flow: column;
    height: 8rem;
    width: auto;
    margin: 0;
    grid-column: 1/3;
  }
`;

const AdminPanelNavWrapperTwo = styled('div')`
  width: 100%;
  display: grid;
  grid-template-rows: min-content;
  height: min-content;
  @media screen and (max-width: 768px) {
    grid-auto-flow: column;
    height: 8rem;
    width: auto;
    margin: 0;
    grid-column: 4/6;
  }
`;

const AdminPanelNav = styled(NavLink)`
  padding: 1rem;
  color: white;
  text-decoration: none;
  font-size: 3rem;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid ${Colors.primary_dark};
  &:hover {
    background-color: ${Colors.primary_light};
  }
  &:active {
    background-color: ${Colors.primary_dark};
  }
`;

export default function AdminPanel() {
  return (
    <AdminPanelWrapper>
      <AdminPanelLogo src={logo} />
      <AdminPanelNavWrapper>
        <AdminPanelNav to={'preview'}>
          <Icon $icon={'view'} $marginRight={1} />
          Preview
        </AdminPanelNav>
        <AdminPanelNav to={'order'}>
          <Icon $icon={'order'} $marginRight={1} />
          Order
        </AdminPanelNav>
        <AdminPanelNav to={'categories'}>
          <Icon $icon={'folder'} $marginRight={1} />
          Categories
        </AdminPanelNav>
        <AdminPanelNav to={'items'}>
          <Icon $icon={'folder'} $marginRight={1} />
          Items
        </AdminPanelNav>
      </AdminPanelNavWrapper>
    </AdminPanelWrapper>
  );
}
