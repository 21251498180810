import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Category from '../../category';

const AdminSectionWrapper = styled('ul')`
  overflow: auto;
  display: grid;
  grid-auto-rows: min-content;
  height: 100%;
  box-sizing: border-box;
  margin: 2rem 0 0 0;
  padding: 0 0 2rem;
  overflow: hidden;
`;

const AdminListItem = styled('li')``;

const AdminSection = ({ array, count }: any) => {
  return (
    <Droppable droppableId={`${count}`}>
      {(provided) => (
        <AdminSectionWrapper
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {array.map((category: any, categoryKey: any) => {
            return (
              <Draggable
                key={category.id}
                draggableId={category.id}
                index={categoryKey}
              >
                {(provided) => (
                  <AdminListItem
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    key={categoryKey}
                  >
                    <Category
                      key={categoryKey}
                      data={category}
                      textColor={category.textColor}
                    />
                  </AdminListItem>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </AdminSectionWrapper>
      )}
    </Droppable>
  );
};

export default AdminSection;
