import { useEffect, useRef, useState } from 'react';
import TextField from '../../global/textfield';
import UseMenu from '../../../hooks/menu/index';
import {
  AdminButtons,
  AdminCategoryThc,
  AdminCategoryWrapper,
  AdminColorButton,
  AdminColorButtonWrapper,
  AdminColorPickerWrapper,
  AdminColorSwatch,
  AdminColorWrapper,
  AdminDeleteButton,
  AdminDeleteTitle,
  AdminDeleteWrapper,
  AdminItemGroup,
  AdminSaveButton,
  AdminSwitchButton,
  AdminTextField,
  HexCustomInput,
  HexCustomWrapper,
  HexSelectWrapper,
} from './style';
import { HexColorPicker } from 'react-colorful';
import { Colors } from '../../../assets/style/global';

const Category = ({
  data,
}: {
  data: {
    id: string;
    title?: string;
    thc?: boolean;
    custom?: boolean;
    color?: string;
    itemGroup: number;
    section: number;
    textColor: boolean;
  };
}) => {
  const [title, setTitle] = useState<string>('');
  const [color, setColor] = useState<string>('white');
  const [thc, setThc] = useState<boolean>(false);
  const [custom, setCustom] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<boolean>(false);
  const [editColor, setEditColor] = useState<boolean>(false);
  const [itemGroup, setItemGroup] = useState<number>(0);
  const [deleteCategory, setDeleteCategory] = useState<boolean>(false);
  const [really, setReally] = useState<boolean>(false);
  const ref = useRef<any>();
  const buttonRef = useRef<any>();
  const { deleteItem, saveData } = UseMenu();

  useEffect(() => {
    if (data.title) setTitle(data.title);
    if (data.color) setColor(data.color);
    if (data.thc) setThc(data.thc);
    if (data.custom) setCustom(data.custom);
    if (data.textColor) setTextColor(data.textColor);
    if (data.itemGroup) setItemGroup(data.itemGroup);
    const handleOutsideClick = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setEditColor(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [data]);

  return (
    <AdminCategoryWrapper $color={Colors.gray}>
      {deleteCategory ? (
        really ? (
          <AdminDeleteWrapper>
            <AdminDeleteTitle>
              Are you really sure you want to delete {title}?
            </AdminDeleteTitle>
            <AdminSwitchButton
              $state={true}
              style={{ cursor: 'pointer' }}
              disabled={false}
              onClick={() => {
                deleteItem(data);
                setReally(false);
                setDeleteCategory(false);
              }}
            >
              Yes
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ cursor: 'pointer' }}
              disabled={false}
              onClick={() => setDeleteCategory(false)}
            >
              No
            </AdminSwitchButton>
          </AdminDeleteWrapper>
        ) : (
          <AdminDeleteWrapper>
            <AdminDeleteTitle>
              Do you really want to delete {title}?
            </AdminDeleteTitle>
            <AdminSwitchButton
              $state={true}
              style={{ cursor: 'pointer' }}
              disabled={false}
              onClick={() => setReally(true)}
            >
              Yes
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ cursor: 'pointer' }}
              disabled={false}
              onClick={() => setDeleteCategory(false)}
            >
              No
            </AdminSwitchButton>
          </AdminDeleteWrapper>
        )
      ) : (
        <>
          <AdminTextField>
            <TextField
              label={'Titel'}
              name='Title'
              value={title}
              type='text'
              error=''
              onChange={(e: any) => setTitle(e.target.value)}
              validate={false}
            />
          </AdminTextField>
          <AdminColorWrapper>
            Colors:
            <HexCustomWrapper>
              HexCode:
              <HexCustomInput
                color={color}
                onChange={setColor}
                $color={color}
              />
            </HexCustomWrapper>
            <HexSelectWrapper>
              Select:
              <AdminColorButtonWrapper>
                <AdminColorSwatch
                  ref={buttonRef}
                  onClick={() => {
                    setEditColor(!editColor);
                  }}
                >
                  <AdminColorButton $color={color} />
                </AdminColorSwatch>
                {editColor && (
                  <AdminColorPickerWrapper ref={ref}>
                    <HexColorPicker color={color} onChange={setColor} />
                  </AdminColorPickerWrapper>
                )}
              </AdminColorButtonWrapper>
            </HexSelectWrapper>
          </AdminColorWrapper>
          <AdminItemGroup>
            <TextField
              label={'Item Group'}
              name='ItemGroup'
              value={itemGroup}
              type='number'
              error=''
              onChange={(e: any) => setItemGroup(e.target.value)}
              validate={false}
            />
          </AdminItemGroup>
          <AdminCategoryThc>
            THC:
            <AdminSwitchButton
              $state={true}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={thc}
              onClick={() => setThc(true)}
            >
              ON
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={!thc}
              onClick={() => setThc(false)}
            >
              OFF
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminCategoryThc>
            Custom:
            <AdminSwitchButton
              $state={true}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={custom}
              onClick={() => setCustom(true)}
            >
              True
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ fontSize: 'inherit', cursor: 'pointer' }}
              disabled={!custom}
              onClick={() => setCustom(false)}
            >
              False
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminCategoryThc>
            Text color:
            <AdminSwitchButton
              $state={true}
              style={{ cursor: 'pointer' }}
              disabled={textColor}
              onClick={() => setTextColor(true)}
            >
              True
            </AdminSwitchButton>
            <AdminSwitchButton
              $state={false}
              style={{ cursor: 'pointer' }}
              disabled={!textColor}
              onClick={() => setTextColor(false)}
            >
              False
            </AdminSwitchButton>
          </AdminCategoryThc>
          <AdminButtons>
            <AdminSaveButton
              onClick={() =>
                saveData({
                  id: data.id,
                  title: title,
                  thc: thc,
                  itemGroup,
                  color,
                  custom,
                  textColor,
                })
              }
            >
              Save
            </AdminSaveButton>
            <AdminDeleteButton onClick={() => setDeleteCategory(true)}>
              Delete
            </AdminDeleteButton>
          </AdminButtons>{' '}
        </>
      )}
    </AdminCategoryWrapper>
  );
};
export default Category;
