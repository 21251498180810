import styled from 'styled-components';
import TextField from '../../global/textfield';
import { Colors } from '../../../assets/style/global';
import { useState } from 'react';
import validator from 'validator';
import useAuth from '../../../hooks/auth';

const LoginWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled('form')`
  display: grid;
  font-size: clamp(2rem, 1.6vw, 4rem);
  background-color: ${Colors.gray_dark};
  padding: clamp(3rem, 3vw, 8rem);
  padding-top: clamp(2rem, 3vw, 5rem);
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const LoginTitle = styled('h1')`
  font-size: clamp(4rem, 3vw, 8rem);
  text-transform: uppercase;
  color: ${Colors.white};
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
`;

const LoginButton = styled('button')`
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  &:hover {
    background-color: rgba(0, 230, 119, 0.1);
  }
  &:active {
    background-color: rgba(0, 230, 119, 0.5);
  }
  border: 0.2rem solid ${Colors.primary};
`;

const AdminLogin = ({
  username,
  password,
  setPassword,
  setUsername,
  setUser,
}: any) => {
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { login } = useAuth();
  const submitLogin = async (e: any) => {
    e.preventDefault();
    if (validator.isEmpty(username)) {
      setUsernameError('This field is required!');
      return;
    }
    if (validator.isEmpty(password)) {
      setPasswordError('This field is required!');
      return;
    }

    const res = await login(username, password);
    if (res.isUser) {
      setUsernameError(res.isUser);
      return;
    }
    if (res.password) {
      setPasswordError(res.password);
      return;
    }
    setUser(res);
  };

  return (
    <LoginWrapper>
      <LoginForm onSubmit={submitLogin}>
        <LoginTitle>Login</LoginTitle>
        <TextField
          label='Username'
          name='username'
          value={username}
          type='text'
          onChange={(e: any) => {
            setUsername(e.target.value);
            setUsernameError('');
          }}
          error={usernameError}
          validate={true}
        />
        <TextField
          label='password'
          name='password'
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value);
            setPasswordError('');
          }}
          type='password'
          error={passwordError}
          validate={true}
        />
        <LoginButton>Login</LoginButton>
      </LoginForm>
    </LoginWrapper>
  );
};

export default AdminLogin;
