import styled from 'styled-components';
import Category from '../category';
import AddCategory from '../category/addCategory';

const CategoriesWrapper = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  grid-gap: 3rem;
  color: white;
  overflow: auto;
  height: max-content;

  @media screen and (max-width: 2559px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1444px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default function Categories({ categories }: any) {
  return (
    <CategoriesWrapper>
      {categories.map((data: any, i: any) => (
        <Category key={i} data={data} />
      ))}
      <AddCategory />
    </CategoriesWrapper>
  );
}
