import styled from 'styled-components';
import { Colors } from '../../../assets/style/global';
import { HexColorInput } from 'react-colorful';

// prettier-ignore
const AdminCategoryWrapper = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  display: grid;
  justify-items: left;
  position: relative;
  padding: 2rem;
  border-radius: .5rem;
  font-size: clamp(2rem, 1.37vw, 3rem);
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    font-size: clamp(1.8rem, 2vw, 2rem);
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    font-size: clamp(1rem, 2vw, 1.5rem);
    margin-bottom: .5rem;
  }
`;

const AdminCategoryTitle = styled('div')`
  user-select: none;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const AdminCategoryThc = styled('div')`
  height: min-content;
  user-select: none;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  justify-self: start;
  color: white;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;
const AdminCategoryTitlePrice = styled('div')`
  user-select: none;
  grid-column: 3/-1;
  justify-self: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const AdminEditButton = styled('button')`
  cursor: pointer;
  justify-self: center;
  border-radius: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 3rem;
  width: 3rem;

  @media screen and (max-width: 1024px) {
    width: clamp(2rem, 3vw, 3rem);
    height: clamp(2rem, 3vw, 3rem);
  }
  text-align: center;
  font-size: inherit;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: '\\e901';
  }
`;

const AdminItemGroup = styled('div')`
  width: 100%;
  user-select: none;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const AdminItemGroupInput = styled('input')`
  width: 12rem;
  font-size: inherit;
  user-select: none;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  background-color: transparent;
  border-radius: 1rem;
`;
const AdminTitleInput = styled('input')`
  width: 100%;
  text-align: center;
  font-size: 3rem;
  background-color: transparent;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  border-radius: 1rem;
`;

const AdminColorWrapper = styled('div')`
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  user-select: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  height: min-content;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  width: 100%;
`;

const AdminColorButtonWrapper = styled('div')`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  width: 100%;
`;

//@ts-ignore
const AdminColorButton = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  cursor: pointer;
  height: 1.4rem;
  width: 100%;
  border-radius: 0.2rem;
`;

const AdminColorSwatch = styled('div')`
  padding: 0.5rem;
  border-radius: 0.6rem;
  display: inline-block;
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.233);
  border: 0.2rem solid ${Colors.primary};
`;

const AdminColorPickerWrapper = styled('div')`
  position: absolute;
  z-index: 4;
  margin-left: 1rem;
  margin-top: 25rem;
`;

const AdminDelete = styled('div')`
  position: fixed;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #0000004e;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
`;

const AdminDeleteBody = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  position: relative;
  min-width: min-content;
  max-width: max-content;
  display: grid;
  grid-gap: 1rem;
  justify-items: center;
  grid-template-rows: 1fr;
  padding: 2rem;
  border-radius: 1.5rem;
  font-size: clamp(2rem, 1.37vw, 3.5rem);
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const AdminDeleteText = styled('div')`
  font-size: clamp(2rem, 1.37vw, 3.5rem);
`;

const AdminDeleteButtons = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
`;

const AdminDeleteSelectButton = styled('button')`
  font-size: 2rem;
`;

const AdminAddButton = styled('button')`
  cursor: pointer;
  font-size: inherit;
  margin: 0.3rem;
`;

const AdminCategoryAddButton = styled('button')`
  cursor: pointer;
  font-size: clamp(0.5rem, 1vw, 2rem);
  padding: 0.1rem;
`;

const AdminAddItem = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  padding: 1rem;
  background-color: #8080806a;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const AdminAddItemBody = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  position: relative;
  min-width: min-content;
  max-width: max-content;
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 1.5rem;
  font-size: clamp(2rem, 1.37vw, 3.5rem);
`;

const AdminTextField = styled('div')`
  height: max-content;
  width: 100%;
`;

const AdminSwitchButton = styled('button')<{ $state: boolean }>`
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  &:hover {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.1)'
        : 'rgba(230, 0, 0, 0.1)'};
  }
  &:active {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.5)'
        : 'rgba(230, 0, 0, 0.5)'};
  }
  border: 0.2rem solid
    ${(props: any) => (props.$state === true ? Colors.primary : Colors.error)};

  &:disabled {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.5)'
        : 'rgba(230, 0, 0, 0.5)'};
    cursor: not-allowed !important;
  }
`;

const HexSelectWrapper = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column: 2;
  grid-row: 2;
`;

const HexCustomWrapper = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column: 2;
`;

const HexCustomInput = styled(HexColorInput)<{ $color: string }>`
  font-size: inherit;
  user-select: none;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  background-color: transparent;
  border-radius: 1rem;
  border: 0.2rem solid ${(props) => props.$color};

  &:active {
    border: 0.2rem solid ${(props) => props.$color};
  }
`;

const AdminDeleteButton = styled('button')`
  margin-top: 2rem;
  justify-self: center;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: end;
  grid-gap: 1rem;
  width: 100%;

  &:after {
    justify-self: start;
    content: '\\e903';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    background-color: rgba(230, 0, 0, 0.1);
  }
  &:active {
    background-color: rgba(230, 0, 0, 0.5);
  }
  border: 0.2rem solid ${Colors.error};

  &:disabled {
    background-color: rgba(230, 0, 0, 0.5);
    cursor: not-allowed !important;
  }
`;

const AdminSaveButton = styled('button')`
  margin-top: 2rem;
  justify-self: center;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: end;
  grid-gap: 1rem;
  width: 100%;

  &:after {
    justify-self: start;
    content: '\\e902';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    background-color: rgba(0, 230, 119, 0.1);
  }
  &:active {
    background-color: rgba(0, 230, 119, 0.5);
  }
  border: 0.2rem solid ${Colors.primary};

  &:disabled {
    background-color: rgba(0, 230, 119, 0.5);
    cursor: not-allowed !important;
  }
`;

const AdminButtons = styled('div')`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 2rem;
`;

const AdminDeleteWrapper = styled('div')`
  display: grid;
  font-size: inherit;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
`;
const AdminDeleteTitle = styled('div')`
  grid-column: 1/-1;
  font-weight: 700;
`;

export {
  AdminDeleteWrapper,
  HexSelectWrapper,
  HexCustomWrapper,
  AdminButtons,
  AdminSaveButton,
  AdminSwitchButton,
  AdminTextField,
  AdminCategoryAddButton,
  AdminAddItemBody,
  AdminAddItem,
  AdminAddButton,
  AdminColorPickerWrapper,
  AdminColorSwatch,
  AdminColorButton,
  AdminColorButtonWrapper,
  AdminColorWrapper,
  AdminTitleInput,
  AdminItemGroupInput,
  AdminItemGroup,
  AdminEditButton,
  AdminCategoryWrapper,
  AdminCategoryTitle,
  AdminCategoryThc,
  AdminCategoryTitlePrice,
  AdminDeleteButton,
  AdminDelete,
  AdminDeleteBody,
  AdminDeleteText,
  AdminDeleteTitle,
  AdminDeleteButtons,
  AdminDeleteSelectButton,
  HexCustomInput,
};
