import styled from 'styled-components';
import { Colors } from '../../../assets/style/global';

const TextFieldWrapper = styled('div')`
  background-color: transparent;
  color: ${Colors.white};
  position: relative;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: inherit;
`;

const TextFieldLabel = styled('label')<{
  $error: string | undefined;
  $validate: boolean;
}>`
  font-size: inherit;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 0;
  user-select: none;
  pointer-events: none;
  color: inherit;
  color: ${(props) => (props.$error ? Colors.error : Colors.white)};
  transform: translateY(-50%);
  transition: 300ms;
`;
const BorderLeft = styled('div')`
  position: absolute;
  border-bottom: 0.1rem solid ${Colors.white};
  height: 100%;
  width: 50%;
  right: 50%;
  top: 0;
  pointer-events: none;
`;
const BorderRight = styled('div')`
  position: absolute;
  border-bottom: 0.1rem solid ${Colors.white};
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  pointer-events: none;
`;
const BorderLeftColors = styled('div')<{
  $error: string | undefined;
  $validate: boolean;
}>`
  position: absolute;
  border-bottom: 0.1rem solid
    ${(props) =>
      props.$validate
        ? props.$error
          ? Colors.error
          : Colors.success
        : Colors.white};
  height: 100%;
  width: ${(props) => (props.$error ? '50%' : '0%')};
  top: 0;
  right: 50%;
  transition: 300ms;
  pointer-events: none;
  z-index: 2;
`;
const BorderRightColors = styled('div')<{
  $error: string | undefined;
  $validate: boolean;
}>`
  position: absolute;
  border-bottom: 0.1rem solid
    ${(props) =>
      props.$validate
        ? props.$error
          ? Colors.error
          : Colors.success
        : Colors.white};
  height: 100%;
  width: ${(props) => (props.$error ? '50%' : '0%')};
  top: 0;
  left: 50%;
  transition: 300ms;
  pointer-events: none;
  z-index: 2;
`;

const TextFieldInput = styled('input')<{
  $error: string | undefined;
  $validate: boolean;
}>`
  font-size: inherit;
  color: ${Colors.white};
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
    font-size: inherit;
    color: ${Colors.white};
  }
  &:focus + ${TextFieldLabel} {
    color: ${(props) =>
      props.$validate
        ? props.$error
          ? Colors.error
          : Colors.success
        : Colors.white};
    top: 0;
    font-size: inherit;
    transform: translateY(-70%) scale(0.7) translateX(-20%);
  }
  &:not([value='']) + ${TextFieldLabel} {
    color: ${(props) =>
      props.$validate
        ? props.$error
          ? Colors.error
          : Colors.success
        : Colors.white};
    top: 0;
    font-size: inherit;
    transform: translateY(-70%) scale(0.7) translateX(-20%);
  }
  &:focus ~ ${BorderRightColors} {
    width: 50%;
  }
  &:not([value='']) ~ ${BorderRightColors} {
    width: 50%;
  }
  &:focus ~ ${BorderLeftColors} {
    width: 50%;
  }
  &:not([value='']) ~ ${BorderLeftColors} {
    width: 50%;
  }
`;

const ErrorText = styled('div')`
  position: absolute;
  font-size: 1.5rem;
  color: ${Colors.error};
  bottom: 0;
  transform: translateY(100%);
`;

export default function TextField({
  label,
  name,
  value,
  error,
  type,
  onChange,
  validate,
}: {
  label: string;
  name: string;
  value: string | number;
  type: string;
  error?: string;
  onChange: any;
  validate: boolean;
}) {
  return (
    <TextFieldWrapper>
      <TextFieldInput
        name={name}
        value={value}
        onChange={onChange}
        $error={error}
        type={type}
        $validate={validate}
      />
      <TextFieldLabel htmlFor={name} $error={error} $validate={validate}>
        {label}
      </TextFieldLabel>
      <BorderLeft />
      <BorderRight />
      <BorderLeftColors $error={error} $validate={validate} />
      <BorderRightColors $error={error} $validate={validate} />
      <ErrorText>{error}</ErrorText>
    </TextFieldWrapper>
  );
}
