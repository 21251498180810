import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Menu from './pages/menu';
import Admin from './pages/admin';

import './assets/icons/fonts.scss';

export const AppRoot = createGlobalStyle`
  

  * {
     font-family: MainFont;
     box-sizing: border-box;
    }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    @media screen and (max-height: 1000px) {
        overflow: auto;
      }
      
  }

  #root,
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; //1rem = 10px
  }

  iframe#webpack-dev-server-client-overlay{display:none!important}
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <AppRoot />
    <Routes>
      <Route path='/' element={<Menu />} />
      <Route path='/admin/*' element={<Admin />} />
    </Routes>
  </Router>
);
